import { ReactNode } from 'react'
import NextLink from 'next/link'
import cx from 'classnames'

import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityFooterSettings } from '@data/sanity/queries/types/site'

import Menu from '@blocks/navigation/menu'
import Photo from '@components/photo'
import Icon, { IconName } from '@components/icon'

interface FooterBlockProps {
  title?: string
  children?: ReactNode
}

const FooterBlock = ({ title, children }: FooterBlockProps) => (
  <div className="flex flex-col gap-y-5">
    <h4>{title}</h4>
    {children}
  </div>
)

interface FooterLinkProps {
  id: string
  children: ReactNode
  href?: string
  icon: IconName
}

const FooterLink = ({ id, children, href, icon }: FooterLinkProps) => (
  <a
    href={href}
    className={cx('inline-flex items-start gap-x-2.5 text-base group', {
      'pointer-events-none': !href,
    })}
  >
    <span className="bg-white bg-opacity-[0.05] rounded-full p-2 text-xl">
      <Icon id={id} name={icon} />
    </span>
    <span className="mt-2 group-hover:opacity-60 transition-opacity duration-200">
      {children}
    </span>
  </a>
)

type FooterProps = SanityFooterSettings & {
  logo?: SanityImageFragment
}

const Footer = ({
  company,
  menus,
  legalMenu,
  copyright,
  logo,
}: FooterProps) => {
  return (
    <footer
      className="relative overflow-hidden bg-pageText text-pageBG pt-16"
      role="contentinfo"
    >
      <div className="container flex flex-col gap-y-12">
        {logo && (
          <NextLink href="/" className="inline-flex">
            <Photo image={logo} />
          </NextLink>
        )}

        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-10">
          {company && (
            <FooterBlock title={company.name}>
              <div className="flex flex-col gap-y-3">
                {company.phone && (
                  <FooterLink
                    href={`tel:${company.phone}`}
                    id="footer-company-phone"
                    icon="Dispatcher"
                  >
                    {company.phone}
                  </FooterLink>
                )}
                {company.email && (
                  <FooterLink
                    href={`mailto:${company.email}`}
                    id="footer-company-email"
                    icon="Mail"
                  >
                    {company.email}
                  </FooterLink>
                )}
                {company.address && (
                  <FooterLink id="footer-company-address" icon="Globe">
                    {company.address}
                  </FooterLink>
                )}
              </div>
            </FooterBlock>
          )}

          {menus?.map(({ title, items }, index) => (
            <FooterBlock title={title} key={`footer-block-${index}`}>
              <Menu items={items} isFooterMenu />
            </FooterBlock>
          ))}
        </div>

        <div className="flex flex-col xs:flex-row xs:items-center xs:flex-wrap gap-5 py-5 xs:mt-10 border-t border-white border-opacity-10">
          {copyright && <p className="text-xs">{copyright}</p>}
          {legalMenu && <Menu items={legalMenu.items} isFooterLegalMenu />}

          <a
            className="text-xs mx-auto xs:ml-auto xs:mr-0 group"
            href="https://nethart.com"
          >
            <span className="opacity-30">Built by</span>{' '}
            <span className="opacity-30 group-hover:opacity-100 transition-opacity">
              Nethart - Digital Craftshop
            </span>
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
