import cx from 'classnames'
import {
  act,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { type SanityProductVariantFragment } from '@data/sanity/queries/types/product'
import { AnalyticsEventName } from '@lib/analytics'
import { AnalyticsContext } from '@lib/analytics-context'
import { useUser } from '@lib/auth'
import { CartContext } from '@lib/cart/context'
import { LanguageContext } from '@lib/language-context'
import {
  getDefaultOption,
  getVariantByDefaultOption,
  useProductWithInventory,
} from '@lib/product'
import { ProductContext } from '@lib/product-context'
import { StringsContext } from '@lib/strings-context'

import ProductAddToCart from '@blocks/product/product-add-to-cart'
import ProductCounter from '@blocks/product/product-counter'
import ProductOptionsSelect from '@blocks/product/product-options-select'
import ProductStockLabel from '@blocks/product/product-stock'
import Icon from '@components/icon'
import Alert from './alert'
import { ButtonVariant } from './buttons/button'
import SimplePortableText from './simple-portable-text'

const QuickAddModal = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { triggerAnalyticsEvent } = useContext(AnalyticsContext)
  const { addItemsToCart } = useContext(CartContext)
  const { locale } = useContext(LanguageContext)
  const { quickAddModal, toggleQuickAddModal } = useContext(ProductContext)
  const strings = useContext(StringsContext)

  const { user } = useUser()

  const [quantity, setQuantity] = useState(1)
  const [isAddToCartError, setIsAddToCartError] = useState(false)
  const [activeVariantId, setActiveVariantId] = useState<number | null>(null)

  // Get active product and variant
  const productWithInventory = useProductWithInventory(
    locale,
    quickAddModal.product,
  )
  const activeVariant = useMemo(
    () =>
      productWithInventory?.variants?.find(
        (variant) => variant.variantID === activeVariantId,
      ),
    [productWithInventory?.variants, activeVariantId],
  )

  // Handle modal close
  useEffect(() => {
    if (productWithInventory) {
      return
    }

    setQuantity(1)
    setIsAddToCartError(false)
    setActiveVariantId(null)
  }, [productWithInventory])

  // Set default active variant ID when opening modal
  useEffect(() => {
    if (productWithInventory && activeVariantId === null) {
      const firstVariantId = productWithInventory.variants?.[0]?.variantID

      if (firstVariantId) {
        setActiveVariantId(firstVariantId)
      }
    }
  }, [productWithInventory, activeVariantId])

  useEffect(() => {
    document.body.classList.toggle('max-h-screen', quickAddModal.isOpen)
    document.body.classList.toggle('overflow-hidden', quickAddModal.isOpen)

    return () => {
      document.body.classList.toggle('max-h-screen', false)
      document.body.classList.toggle('overflow-hidden', false)
    }
  }, [quickAddModal.isOpen])

  useEffect(() => {
    function handleEscapeKeyPress(event: KeyboardEvent) {
      if (event.key === 'Escape' && quickAddModal.isOpen) {
        toggleQuickAddModal(false)
      }
    }

    document.addEventListener('keydown', handleEscapeKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [quickAddModal.isOpen, toggleQuickAddModal])

  const handleVariantChange = useCallback(
    (variant: SanityProductVariantFragment) => {
      if (!productWithInventory) {
        return
      }

      const variantIds =
        productWithInventory.variants?.map(
          (productWithInventoryVariant) =>
            productWithInventoryVariant.variantID,
        ) ?? []
      const isValidVariantId = variantIds.some((id) => id === variant.variantID)

      if (isValidVariantId) {
        setActiveVariantId(variant.variantID)
        return
      }

      // Set default variant
      const defaultOption = getDefaultOption(
        productWithInventory.options,
        productWithInventory.optionSettings ?? [],
      )
      const defaultVariant = getVariantByDefaultOption(
        productWithInventory.variants ?? [],
        defaultOption,
      )
      const defaultVariantId = defaultVariant?.variantID

      if (defaultVariantId) {
        setActiveVariantId(defaultVariantId)
        return
      }

      // Set first variant
      const firstVariantId = productWithInventory.variants?.[0]?.variantID

      if (firstVariantId) {
        setActiveVariantId(firstVariantId)
      }
    },
    [productWithInventory],
  )

  const handleAddToCart = async () => {
    setIsAddToCartError(false)

    if (!activeVariant) {
      return
    }

    const isSuccessful = await addItemsToCart([
      {
        id: activeVariant.variantID,
        quantity,
      },
    ])

    setIsAddToCartError(!isSuccessful)

    if (isSuccessful) {
      triggerAnalyticsEvent(AnalyticsEventName.AddToCart)

      toggleQuickAddModal(false)
    }
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!quickAddModal.isOpen) {
        return
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        toggleQuickAddModal(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [ref, quickAddModal.isOpen, toggleQuickAddModal])

  if (
    !quickAddModal.isOpen ||
    !quickAddModal.product ||
    !productWithInventory ||
    !activeVariant
  ) {
    return null
  }

  const isUserLoading = typeof user === 'undefined'
  const isUserLoggedIn = !!user?.isLoggedIn

  return (
    <div
      className={cx(
        'fixed flex items-center justify-center inset-0 z-80 bg-backdrop bg-opacity-25 pointer-events-none p-5',
        'transition-opacity duration-150 ease-linear',
        {
          'opacity-0': !quickAddModal.isOpen,
          'pointer-events-auto opacity-100 _backdrop-blur-[6px]':
            quickAddModal.isOpen,
        },
      )}
    >
      <div
        ref={ref}
        className="flex flex-col bg-pageBG rounded-lg max-w-[600px] max-h-full w-full"
      >
        <div className="flex items-center justify-between gap-3 px-5 py-3 md:py-5">
          <span className="text-sm font-medium">
            {strings.productOptionsHeading}
          </span>

          <button
            onClick={() => toggleQuickAddModal(false)}
            className="inline-flex justify-center items-center p-2 rounded-full border border-divider text-xs"
          >
            <Icon id="quick-add-modal-close-icon" name="Cross" />
          </button>
        </div>

        <div className="overflow-y-scroll no-scrollbar px-5 pb-5">
          <ProductOptionsSelect
            product={productWithInventory}
            activeVariant={activeVariant}
            onChange={handleVariantChange}
          />
        </div>

        {!isUserLoading && isUserLoggedIn && (
          <div className="p-5">
            {activeVariant.inStock && (
              <>
                <div className="flex gap-6">
                  <ProductCounter
                    id={`${activeVariant.variantID}`}
                    max={10}
                    onUpdate={setQuantity}
                    className="flex-none"
                  />

                  <ProductAddToCart
                    variant={ButtonVariant.FILLED}
                    onClick={handleAddToCart}
                    className="flex-auto"
                  >
                    {strings.buttonAddToCart}
                  </ProductAddToCart>
                </div>

                {isAddToCartError && (
                  <Alert error className="mt-4" key="error">
                    <SimplePortableText
                      className="rc rc-alert rc-error"
                      content={strings.cartAddToCartErrorMessage}
                    />
                  </Alert>
                )}
              </>
            )}

            {!activeVariant.inStock && (
              <div className="text-center">
                <ProductStockLabel productVariant={activeVariant} size="sm" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default QuickAddModal
