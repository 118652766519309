import {
  type CSSProperties,
  type KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { getActiveLinkCount } from '@lib/navigation'
import { SiteContext } from '@lib/site-context'
import { useIsTouchDevice } from '@lib/helpers'

import DrawerMenu from '@components/drawer-menu'
import MegaNavigationDropdown from './mega-navigation-dropdown'

export interface CSSPropertiesWithHeight extends CSSProperties {
  '--h': number
  '--hpx': string
}

interface MegaNavigationProps {
  items: SanityMenuFeaturedLinkFragment[]
  headerHeight: number
}

const MegaNavigation = ({ items, headerHeight }: MegaNavigationProps) => {
  const {
    megaNavigation,
    toggleMegaNavigation,
    megaNavigationTimeout,
    setMegaNavigationTimeout,
  } = useContext(SiteContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [activeDropdownHeight, setActiveDropdownHeight] = useState<number>()

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const isTouchDevice = useIsTouchDevice()

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', megaNavigation.isOpen)
  }, [megaNavigation.isOpen])

  const dropdowns = items.filter(
    (item) => item._type === 'navDropdown' && 'dropdownItems' in item,
  )

  // Update dropdown open state when closing menu
  useEffect(() => {
    if (!megaNavigation.isOpen && isDropdownOpen) {
      setIsDropdownOpen(false)
    }
  }, [isDropdownOpen, megaNavigation.isOpen])

  // Toggle overflow-hidden body class
  useEffect(() => {
    if (!isLoaded && megaNavigation.isOpen) {
      setIsLoaded(true)
    }

    if (typeof window !== 'undefined') {
      document.body.classList.toggle('overflow-hidden', megaNavigation.isOpen)
    }
  }, [isLoaded, megaNavigation.isOpen])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Escape') {
        toggleMegaNavigation(false)
      }
    },
    [toggleMegaNavigation],
  )

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      if (megaNavigationTimeout) {
        window.clearTimeout(megaNavigationTimeout)
      }
    }
  }

  const handleMouseLeave = () => {
    if (!isTouchDevice) {
      setMegaNavigationTimeout(
        window.setTimeout(() => {
          toggleMegaNavigation(false)
        }, 300),
      )
    }
  }

  const activeLinkCount = getActiveLinkCount(
    dropdowns,
    megaNavigation.isOpen,
    megaNavigation.activeId,
  )

  if (dropdowns.length === 0) {
    return null
  }

  return (
    <DrawerMenu
      headerHeight={headerHeight}
      activeDropdownHeight={activeDropdownHeight}
      isOpen={megaNavigation.isOpen}
      hasFocus={hasFocus}
      hasActiveLinks={activeLinkCount > 0}
      onClose={() => toggleMegaNavigation(false)}
      onKeyDown={handleKeyDown}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      menuClassName="hidden lg:block"
      backgroundClassName="hidden lg:block"
      backdropClassName="hidden lg:block backdrop-blur-[6px]"
    >
      {dropdowns.map((dropdown) => (
        <MegaNavigationDropdown
          key={dropdown._key}
          dropdown={dropdown}
          hasFocus={hasFocus}
          setHasFocus={setHasFocus}
          setActiveDropdownHeight={setActiveDropdownHeight}
        />
      ))}
    </DrawerMenu>
  )
}

export default MegaNavigation
