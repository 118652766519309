import { useContext } from 'react'

import { StringsContext } from '@lib/strings-context'
import { useUser } from '@lib/auth'
import { getLinkPageUrl } from '@lib/routes'

import ButtonLink, {
  ButtonColor,
  ButtonIconAlignment,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button-link'
import { ButtonProps } from '@components/buttons/button'

type SharedButtonProps = Pick<
  ButtonProps,
  'variant' | 'size' | 'color' | 'hideLabelUntil' | 'icon'
>

const AccountButton = () => {
  const strings = useContext(StringsContext)

  const { user } = useUser()

  const sharedButtonProps: SharedButtonProps = {
    variant: ButtonVariant.OUTLINED,
    size: ButtonSize.SMALL,
    color: ButtonColor.GRAY,
    hideLabelUntil: 'md',
    icon: 'User',
  }

  if (!user) {
    return <ButtonLink {...sharedButtonProps} />
  }

  if (!user.isLoggedIn) {
    return (
      <ButtonLink
        {...sharedButtonProps}
        href={getLinkPageUrl('loginPage')}
        iconAlignment={ButtonIconAlignment.RIGHT}
      >
        {strings.buttonLogin}
      </ButtonLink>
    )
  }

  return (
    <ButtonLink
      {...sharedButtonProps}
      href={getLinkPageUrl('accountPage')}
      iconAlignment={ButtonIconAlignment.LEFT}
    >
      {user.customer
        ? `${user.customer?.firstName.charAt(0)}. ${user.customer?.lastName}`
        : ''}
    </ButtonLink>
  )
}

export default AccountButton
