import cx from 'classnames'
import { motion } from 'framer-motion'
import {
  type BaseSyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { useForm } from 'react-hook-form'
import { useResizeObserver } from 'usehooks-ts'

import { swipeAnimation } from '@lib/animate'
import { type SearchFormValues } from '@lib/search'
import { SearchContext } from '@lib/search-context'
import { StringsContext } from '@lib/strings-context'

import Icon from '@components/icon'
import InputField from '@components/input-field'
import SearchProductCategoryResults from './search-product-category-results'

interface SearchDropdownProps {
  setHasFocus: (hasFocus: boolean) => void
  setDrawerHeight: (drawerHeight: number) => void
}

const SearchDropdown = ({
  setHasFocus,
  setDrawerHeight,
}: SearchDropdownProps) => {
  const {
    queryInputValue,
    searchDrawer,
    setQueryInputValue,
    setQuerySuggestionValue,
  } = useContext(SearchContext)
  const strings = useContext(StringsContext)

  const { handleSubmit, register, setFocus } = useForm<SearchFormValues>()
  const queryRegister = register('query', {
    onChange: (event) => setQueryInputValue(event.target.value),
  })

  const drawerRef = useRef<HTMLDivElement>(null)
  const drawerRectangle = useResizeObserver({
    ref: drawerRef,
  })

  useEffect(() => {
    if (searchDrawer.isOpen && typeof drawerRectangle.height !== 'undefined') {
      setDrawerHeight(drawerRectangle.height)
    }
  }, [drawerRectangle.height, searchDrawer, setDrawerHeight])

  const onSubmit = useCallback(
    (searchFormValues: SearchFormValues, event?: BaseSyntheticEvent) => {
      event?.preventDefault()

      if (!searchFormValues.query) {
        // Focus search input
        setFocus('query')
        return
      }

      setQuerySuggestionValue(queryInputValue)
    },
    [queryInputValue, setFocus, setQuerySuggestionValue],
  )

  return (
    <div
      ref={drawerRef}
      id="search-dropdown"
      className={cx(
        'absolute top-0 inset-x-0 z-10 -mt-px overflow-hidden transition-visibility duration-200',
        {
          'delay-[0s] pointer-events-auto': searchDrawer.isOpen,
          'invisible delay-[.8s] pointer-events-none': !searchDrawer.isOpen,
        },
      )}
    >
      <div>
        <div>
          <motion.div
            initial="hide"
            animate={searchDrawer.isOpen ? 'show' : 'hide'}
            onAnimationComplete={(definition) =>
              setHasFocus(definition === 'show')
            }
            variants={swipeAnimation}
            className="relative will-change-transform"
          >
            {searchDrawer.isOpen && (
              <>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex sm:hidden items-center border-b"
                >
                  <InputField
                    id="search-query-mobile"
                    type="text"
                    formRegister={queryRegister}
                    placeholder={strings.searchPlaceholder}
                    value={queryInputValue}
                    noBorder
                    className="flex-auto"
                    inputClassName="py-5 px-5"
                  />

                  {!!queryInputValue && (
                    <button
                      type="button"
                      onClick={(event) => {
                        event.preventDefault()
                        setQueryInputValue('')
                      }}
                      className={cx(
                        'inline-flex flex-none mr-4',
                        'drop-shadow-lg items-center disabled:opacity-50 transition-all hover:opacity-60',
                        'text-2xl text-current font-normal',
                      )}
                    >
                      <Icon
                        id="search-field-clear-icon"
                        name="Cross"
                        className="opacity-30"
                      />
                    </button>
                  )}
                </form>

                <SearchProductCategoryResults />
              </>
            )}
          </motion.div>
        </div>
      </div>
    </div>
  )
}

export default SearchDropdown
