import { type ReactNode, useContext } from 'react'

import { CartContext } from '@lib/cart/context'
import { StringsContext } from '@lib/strings-context'

import Button, {
  type ButtonProps,
  ButtonSize,
} from '@components/buttons/button'

interface ProductAddToCartProps {
  onClick: () => void
  className?: string
  children: ReactNode
}

const ProductAddToCart = ({
  onClick,
  variant,
  color,
  size = ButtonSize.NORMAL,
  icon,
  className,
  children,
}: ProductAddToCartProps & ButtonProps) => {
  const strings = useContext(StringsContext)
  const { cart, isCartProductAdding } = useContext(CartContext)

  return (
    <Button
      id="product-add-to-cart-button"
      className={className}
      variant={variant}
      color={color}
      size={size}
      icon={icon}
      disabled={!cart || !!isCartProductAdding}
      onClick={onClick}
    >
      {!cart && strings.buttonUnavailable}
      {isCartProductAdding && strings.buttonAdding}
      {cart && !isCartProductAdding && children}
    </Button>
  )
}

export default ProductAddToCart
