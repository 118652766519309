import cx from 'classnames'
import { motion } from 'framer-motion'
import { forwardRef, useContext, useEffect, useState } from 'react'

import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { itemAnimation } from '@lib/animate'
import { useUser } from '@lib/auth'
import { CartContext } from '@lib/cart/context'
import { type Filter, getProductPrices } from '@lib/product'
import { getFilteredProductVariant } from '@lib/product-card'
import { ProductContext } from '@lib/product-context'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import Button, {
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import ProductStock from '@blocks/product/product-stock'
import SimpleLink from '@components/simple-link'

interface ProductCardProps {
  product: SanityProductFragment
  showPrice?: boolean
  showQuickAdd?: boolean
  activeFilters?: Filter[]
  className?: string
  onClick?: () => void
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    { product, showPrice, showQuickAdd, activeFilters, className, onClick },
    ref,
  ) => {
    const { toggleQuickAddModal } = useContext(ProductContext)
    const strings = useContext(StringsContext)
    const { cart, isCartProductAdding } = useContext(CartContext)

    const { user } = useUser()

    const [activeVariant, setActiveVariant] = useState(() =>
      getFilteredProductVariant(product, activeFilters ?? []),
    )

    useEffect(
      () =>
        setActiveVariant(
          getFilteredProductVariant(product, activeFilters ?? []),
        ),
      [activeFilters, product],
    )

    const isUserLoading = typeof user === 'undefined'
    const isUserLoggedIn = !!user?.isLoggedIn

    const galleryPhotos = product.galleryPhotos ?? []
    const listingPhotos = product.listingPhotos ?? []
    const hasThumbnails = listingPhotos.length > 0
    const hasQuickAdd =
      showQuickAdd && isUserLoggedIn && !!activeVariant?.inStock && cart

    // Get product URL
    const productUrl = getLinkPageUrl('product', product.slug.current)

    const [price, comparePrice] = getProductPrices(product, activeVariant, user)

    const isAddingToCart =
      activeVariant && Array.isArray(isCartProductAdding)
        ? isCartProductAdding?.includes(activeVariant.variantID)
        : false

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx('flex flex-col gap-4 group', className)}
      >
        {activeVariant && hasThumbnails && (
          <div className="relative border">
            <SimpleLink
              href={productUrl}
              onClick={onClick}
              onBeforeInput={onClick}
              tabIndex={0}
            >
              <ProductThumbnail
                galleryPhotos={galleryPhotos}
                listingPhotos={listingPhotos}
                activeVariant={activeVariant}
              />
            </SimpleLink>

            {hasQuickAdd && (
              <div className="absolute bottom-2 left-2 group/quick-add">
                <div className="relative p-0.5">
                  <Button
                    icon="Plus"
                    size={ButtonSize.XS}
                    variant={ButtonVariant.OUTLINED}
                    color={ButtonColor.GRAY}
                    disabled={!cart || isAddingToCart}
                    className="bg-white relative z-[1]"
                    onClick={() => toggleQuickAddModal(true, product)}
                  />

                  <span
                    className={cx(
                      'inline-flex items-center h-full absolute -left-0.5 -top-0.5 pl-10 pr-4 bg-[rgba(34,34,34,.7)] text-white text-xxs sm:text-xs rounded-full whitespace-nowrap',
                      'opacity-0 group-hover/quick-add:opacity-100 transition-all',
                    )}
                  >
                    {strings.buttonSeeOptions}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}

        <div className="space-y-2">
          <div className="">
            <SimpleLink
              className="block is-h4 mb-1"
              href={productUrl}
              onClick={onClick}
              onBeforeInput={onClick}
              tabIndex={0}
            >
              {product.title}
            </SimpleLink>

            {showPrice && !isUserLoading && isUserLoggedIn && (
              <ProductPrice
                className="flex items-center justify-center"
                price={price}
                comparePrice={comparePrice}
                variant="sm"
                showOldPrice
              />
            )}

            {activeVariant && (
              <>
                <p className="text-sm">SKU: {activeVariant.sku}</p>
                <ProductStock productVariant={activeVariant} />
              </>
            )}
          </div>
        </div>
      </motion.div>
    )
  },
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
