import { useForm } from 'react-hook-form'

import type {
  SanityProductFragment,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { getVariantFromOptions } from '@lib/product'

import InputDropdown from '@components/input-dropdown'

interface ProductOptionsSelectProps {
  product: SanityProductFragment
  activeVariant: SanityProductVariantFragment
  onChange: (variant: SanityProductVariantFragment) => void
  onPreview?: (variant?: SanityProductVariantFragment) => void
}

const ProductOptionsSelect = ({
  product,
  activeVariant,
  onChange,
  onPreview,
}: ProductOptionsSelectProps) => {
  const optionForm = useForm()

  if (!product.options.length) {
    return null
  }

  return (
    <div className="max-w-sm w-full space-y-5">
      {product.options.map((option) => {
        return (
          <InputDropdown
            id={`product-hero-option-${option.name}-select`}
            key={option.name}
            defaultValue={option.values[0]}
            formRegister={{
              ...optionForm.register(option.name),
              onChange: async ({ target }) => {
                if (!product?.variants || !activeVariant) {
                  return
                }

                const newVariant = getVariantFromOptions(
                  product?.variants,
                  activeVariant.options,
                  option.name,
                  target.value,
                )

                if (
                  newVariant &&
                  newVariant.variantID !== activeVariant.variantID
                ) {
                  onChange(newVariant)
                  onPreview?.(newVariant)
                }
              },
            }}
            label={option.name}
            options={option.values.map((value) => ({
              title: value,
              value,
            }))}
          />
        )
      })}
    </div>
  )
}

export default ProductOptionsSelect
