import cx from 'classnames'

import {
  type SanityLink,
  type SanityLinkFragment,
} from '@data/sanity/queries/types/link'
import { type SanityMenuFeaturedLinkFragment } from '@data/sanity/queries/types/site'
import { getLinkPageUrl } from '@lib/routes'
import { useUser } from '@lib/auth'

import AccountButton from '@components/buttons/account-button'
import LanguageSwitch from '@components/language-switch'
import CustomLink from '@components/link'
import Dropdown from './dropdown'
import MegaNavigationButton from './mega-navigation-button'

export type ItemType = SanityLinkFragment | SanityMenuFeaturedLinkFragment

interface MenuItemProps {
  menuId?: string
  item: ItemType
  hasFocus: boolean
  useMegaNav: boolean
  onClick?: () => void
  isFooterMenuItem?: boolean
  isFooterLegalMenuItem?: boolean
  isHeaderDesktopMenuItem?: boolean
  isHeaderMobilePrimaryMenuItem?: boolean
  isHeaderMobileSecondaryMenuItem?: boolean
  isMegaNavMenuItem?: boolean
}

const MenuItem = ({
  menuId,
  item,
  hasFocus,
  useMegaNav,
  onClick,
  isFooterMenuItem,
  isFooterLegalMenuItem,
  isHeaderDesktopMenuItem,
  isHeaderMobilePrimaryMenuItem,
  isHeaderMobileSecondaryMenuItem,
  isMegaNavMenuItem,
}: MenuItemProps) => {
  const { user } = useUser()

  const title = 'title' in item && item.title ? item.title : ''
  const isButton = 'isButton' in item && item.isButton ? item.isButton : false

  const itemClassNames = cx(
    !isButton
      ? cx(
          'inline-flex hover:opacity-60 transition-opacity duration-200 !no-underline',
          {
            'text-sm': isFooterMenuItem,
            'text-xs': isFooterLegalMenuItem,
            'font-medium text-sm': isHeaderDesktopMenuItem,
            'w-full font-medium text-base py-5 border-b':
              isHeaderMobilePrimaryMenuItem,
            'w-full font-medium text-sm py-3 border-b':
              isHeaderMobileSecondaryMenuItem,
            'font-medium text-lg': isMegaNavMenuItem,
          },
        )
      : {},
  )

  // Language switch
  if (item._type === 'navLanguageSwitch') {
    return (
      <LanguageSwitch
        id={item._key}
        buttonClassName={itemClassNames}
        hasCurrency={item.displayCurrency}
      />
    )
  }

  // Account button
  if (item._type === 'navAccountButton') {
    const isLoggedIn = !!user?.isLoggedIn
    const accountButtonText = isLoggedIn ? item.accountLabel : item.loginLabel
    const accountButtonUrl = getLinkPageUrl(
      isLoggedIn ? 'accountPage' : 'loginPage',
    )

    return (
      <AccountButton
        id={`account-button-${item._key}`}
        href={accountButtonUrl}
        showIcon={item.displayIcon}
        className={cx(itemClassNames, {
          'p-0': !accountButtonText,
        })}
      >
        {accountButtonText}
      </AccountButton>
    )
  }

  // Dropdown list
  if (item._type === 'navDropdown') {
    if (useMegaNav) {
      return (
        <MegaNavigationButton id={item._key} className={itemClassNames}>
          {title}
        </MegaNavigationButton>
      )
    }

    if (!('dropdownItems' in item) || !item.dropdownItems) {
      return null
    }

    return (
      <Dropdown
        id={item._key}
        title={title}
        items={item.dropdownItems}
        onClick={onClick}
        listItemClassName={cx({
          'mx-2 my-0': isHeaderDesktopMenuItem,
          'ml-4': isHeaderMobilePrimaryMenuItem,
          'ml-3': isHeaderMobileSecondaryMenuItem,
        })}
        listItemLinkClassName={itemClassNames}
        buttonClassName={itemClassNames}
      />
    )
  }

  // Single link
  const titleSlug =
    'title' in item ? item.title.toLowerCase().replace(/ /g, '-') : ''
  const link: SanityLink = {
    ...item,
    ...(menuId && {
      id: `${menuId}-${titleSlug}`,
    }),
  }

  return (
    <CustomLink
      link={link}
      onClick={onClick}
      tabIndex={!hasFocus ? -1 : 0}
      className={itemClassNames}
      showCollectionCount={isMegaNavMenuItem}
      showProductCategoryCount={isMegaNavMenuItem}
    >
      {title}
    </CustomLink>
  )
}

export default MenuItem
