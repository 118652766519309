import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { hasObject } from './helpers'
import { type Filter, type FilterValue } from './product'

export interface OptionValue {
  name: string
  value: string
}

/**
 * Gets default product option taking into account filters.
 */
export const getDefaultOptionsWithFilters = (
  product: SanityProductFragment,
  activeFilterValues: FilterValue[],
): OptionValue[] => {
  return activeFilterValues
    .map((filter) => {
      const currentFilter = product.filters?.find(
        (productFilter) =>
          !!productFilter.forOption &&
          productFilter.slug.current === filter.value,
      )

      if (!currentFilter) {
        return null
      }

      const option = currentFilter.forOption.split(':')

      return {
        name: option[0],
        value: option[1],
      } satisfies OptionValue
    })
    .filter(Boolean) as OptionValue[]
}

/**
 * Gets the default product variant based on option values.
 */
const getDefaultProductVariant = (
  product: SanityProductFragment,
  optionValues: OptionValue[],
) => {
  const lastOptionValue =
    optionValues.length > 0 ? optionValues[optionValues.length - 1] : null

  const firstOption = product.options?.[0]
  const firstOptionValue = firstOption
    ? ({
        name: firstOption?.name,
        value: firstOption?.values?.[0],
      } as OptionValue)
    : null

  const optionValue = lastOptionValue ?? firstOptionValue

  if (!optionValue) {
    return null
  }

  return (
    product.variants?.find((variant) =>
      hasObject(variant.options, optionValue),
    ) ?? null
  )
}

/**
 * Gets filter values from filters.
 */
const getFilterValues = (filters: Filter[]): FilterValue[] =>
  filters.flatMap((filter) =>
    filter.values.map((value) => ({
      name: filter.name,
      value,
    })),
  ) ?? []

/**
 * Gets the filtered, default or first product variant based on current active filters.
 */
export const getFilteredProductVariant = (
  product: SanityProductFragment,
  activeFilters: Filter[],
) => {
  const activeFilterValues = getFilterValues(activeFilters)
  const defaultOptions = getDefaultOptionsWithFilters(
    product,
    activeFilterValues,
  )
  const defaultVariant = getDefaultProductVariant(product, defaultOptions)
  const firstVariant = product.variants?.[0]

  return defaultVariant ?? firstVariant ?? null
}
