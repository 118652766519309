import axios from 'axios'
import { useCallback, useContext } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

import { type SanityProductCategoryResult } from '@data/sanity/queries/types/product'
import { type Locale } from './language'
import { LanguageContext } from './language-context'

export interface SearchFormValues {
  query: string
}

export interface SearchProductCategoryResponse {
  productCategories: SanityProductCategoryResult[]
  error?: string
}

/**
 * Sends a request to an API page to get product search results grouped by product categories.
 */
const searchProductCategories = async (locale: Locale, query: string) => {
  try {
    const searchResult = await axios.get<SearchProductCategoryResponse>(
      '/api/search/product-category',
      {
        params: {
          query,
        },
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      },
    )

    if (searchResult.data.error) {
      throw new Error(searchResult.data.error)
    }

    return searchResult.data.productCategories
  } catch (error) {
    console.log(error)
  }
}

/**
 * Product category search result loading helper hook.
 */
export const useDebouncedSearchProductCategories = () => {
  const { locale } = useContext(LanguageContext)

  const loadResults = useCallback(
    async (
      query: string,
      onComplete: (productCategories: SanityProductCategoryResult[]) => void,
    ) => {
      const productCategories = await searchProductCategories(locale, query)
      onComplete(productCategories ?? [])
    },
    [locale],
  )

  return useDebounceCallback(loadResults, 500)
}
