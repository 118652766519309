import cx from 'classnames'
import { type ChangeEvent, type FocusEvent, useState } from 'react'
import { type UseFormRegisterReturn } from 'react-hook-form'

import Icon from './icon'

export interface DropdownOption {
  value: string
  title: string
}

interface InputDropdownProps {
  id: string
  formRegister: UseFormRegisterReturn
  options: DropdownOption[]
  errorMessage?: string
  label?: string
  defaultValue?: string
  placeholder?: string
  borderBottom?: boolean
  className?: string
}

const InputDropdown = ({
  id,
  formRegister,
  options,
  errorMessage,
  label,
  defaultValue,
  placeholder,
  borderBottom,
  className,
}: InputDropdownProps) => {
  const [value, setValue] = useState(defaultValue)

  const selectedOption = options.find((option) => option.value === value)

  return (
    <div className={cx('grid', className)}>
      <div className={cx('flex flex-col relative text-left text-sm')}>
        {label && (
          <label htmlFor={id} className="font-medium mb-2">
            {label}
          </label>
        )}

        <div
          className={cx(
            'relative appearance-none w-full h-full py-3',
            'leading-none',
            'bg-input-bg text-input-text',
            {
              'border-error': errorMessage,
              'border-input-border': !errorMessage,
              'border-b': borderBottom,
              'border px-4 rounded': !borderBottom,
            },
          )}
        >
          <select
            id={id}
            ref={formRegister.ref}
            name={formRegister.name}
            className="absolute block top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer focus:outline-none"
            value={value}
            onBlur={(event: FocusEvent<HTMLSelectElement>) => {
              formRegister.onBlur(event)
              setValue(event.target.value)
            }}
            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
              formRegister.onChange(event)
              setValue(event.target.value)
            }}
            aria-label={label}
          >
            <option value="" disabled>
              {placeholder}
            </option>
            {options.map(({ value, title }) => (
              <option key={value} value={value}>
                {title}
              </option>
            ))}
          </select>

          <span className="flex justify-between items-center pointer-events-none relative pr-6">
            {!!selectedOption && <>{selectedOption.title}</>}
            {!selectedOption && !!placeholder && (
              <span className="text-current opacity-[.3]">{placeholder}</span>
            )}
            {!selectedOption && !placeholder && <span className="h-[1rem]" />}
            <span className="inline-flex items-center text-2xl ml-2 absolute inset-y-0 right-0">
              <Icon
                id="input-dropdown"
                name="ChevronDown"
                className="block"
                color="text-current"
              />
            </span>
          </span>
        </div>

        {errorMessage && (
          <span role="alert" className="mt-2 font-medium text-xs text-error">
            {errorMessage}
          </span>
        )}
      </div>
    </div>
  )
}

export default InputDropdown
