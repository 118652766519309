import { useContext, useState } from 'react'

import { SearchContext } from '@lib/search-context'

import DrawerMenu from '@components/drawer-menu'
import SearchDropdown from './search-dropdown'

interface SearchDrawerProps {
  headerHeight: number
}

const SearchDrawer = ({ headerHeight }: SearchDrawerProps) => {
  const { searchDrawer, toggleSearchDrawer } = useContext(SearchContext)

  const [hasFocus, setHasFocus] = useState(false)
  const [drawerHeight, setDrawerHeight] = useState<number>()

  return (
    <DrawerMenu
      headerHeight={headerHeight}
      activeDropdownHeight={drawerHeight}
      isOpen={searchDrawer.isOpen}
      hasFocus={hasFocus}
      hasActiveLinks={false}
      onClose={() => toggleSearchDrawer(false)}
    >
      <SearchDropdown
        setHasFocus={setHasFocus}
        setDrawerHeight={setDrawerHeight}
      />
    </DrawerMenu>
  )
}

export default SearchDrawer
