import cx from 'classnames'
import { useContext } from 'react'
import NextLink from 'next/link'

import { type SanityLink } from '@data/sanity/queries/types/link'
import { type PhotoImage, getImageDimensions } from '@lib/image'
import { getLinkPageUrl, type PageType } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import Photo from '@components/photo'

interface LogoLinkProps {
  id: string
  image: PhotoImage
  className?: string
}

const LogoLink = ({ id, image, className }: LogoLinkProps) => {
  const strings = useContext(StringsContext)
  const imageDimensions = getImageDimensions(image)

  return (
    <div
      className={cx(
        'flex flex-col justify-center items-center z-10 transition-opacity',
        className,
      )}
    >
      <div className="flex flex-col justify-center items-center w-full">
        <NextLink
          href={getLinkPageUrl('homePage')}
          aria-label={strings.goHomeLabel}
          className="leading-[0]"
        >
          <Photo
            image={image}
            width={imageDimensions?.width}
            height={imageDimensions?.height}
            priority
            className="inline-block relative overflow-hidden"
            imageClassName="block absolute top-0 right-0 bottom-0 left-0 m-auto min-w-[100%] max-w-[100%] min-h-[100%] max-h-[100%] w-0 h-0 overflow-hidden"
            style={{
              width: imageDimensions?.width,
              height: imageDimensions?.height,
            }}
          />
        </NextLink>
      </div>
    </div>
  )
}

export default LogoLink
